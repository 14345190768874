import { Grid } from '@mui/material'
import { styled } from '@mui/system'
import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import { Layout } from '../components/Layout'
import { RingBaseSelect } from '../components/RingBaseSelect'
import { SEO } from '../components/SEO'
import { Txt } from '../components/Txt'

export const Head = () => (
    <SEO
        title="結婚指輪オーダーメイドのご相談はCAD-platinum表参道ショールームへ | 結婚指輪CAD-platinum"
        description="10,000点を超えるカスタマイズからオーダーメイドも選べる結婚指輪CAD-platinumの表参道ショールームでは、デザインや加工のご相談も承っております。納品後のサイズ直しや修理、リメイクもお気軽にお問い合わせ下さい。"
        page="salon"
    />
)

const SalonPage: React.FC = () => {
    return (
        <Layout breadcrumbs={[{ name: 'ショールーム' }]}>
            <Grid container spacing={0}>
                <Grid item sm={2} />
                <Grid item sm={8} xs={12}>
                    <CatchWrapper>
                        <Txt size="l">表参道ショールーム</Txt>
                    </CatchWrapper>
                    <HeroImageWrapper>
                        <StaticImage src="../images/salon/salon1.jpg" alt="" />
                    </HeroImageWrapper>
                    <CatchWrapper>
                        <Txt size="l">ご予約の流れ</Txt>
                    </CatchWrapper>
                    <SalonWrapper>
                        <LeftWrapper>
                            <SalonTitleWrapper>
                                <NumberImageWrapper>
                                    <StaticImage
                                        src="../images/number/01.jpg"
                                        alt=""
                                    />
                                </NumberImageWrapper>
                                <SalonTitle size="l">
                                    まずはリングをカスタマイズ
                                </SalonTitle>
                            </SalonTitleWrapper>
                            <FeatureImageWrapper>
                                <StaticImage
                                    src="../images/customize/g5.jpg"
                                    alt=""
                                />
                            </FeatureImageWrapper>
                        </LeftWrapper>
                        <SalonDescription size="s">
                            WEBでお好みのリングをカスタマイズしたら、リングの詳細ページからご来店の予約フォームをご利用頂けます。試着をご希望のリングが決まっていない場合も、イメージに近いリングの詳細ページからご予約下さい。
                        </SalonDescription>
                    </SalonWrapper>
                    <SalonWrapper>
                        <RightWrapper>
                            <SalonTitleWrapper>
                                <NumberImageWrapper>
                                    <StaticImage
                                        src="../images/number/02.jpg"
                                        alt=""
                                    />
                                </NumberImageWrapper>
                                <SalonTitle size="l">
                                    店舗で試着 アレンジのご相談
                                </SalonTitle>
                            </SalonTitleWrapper>
                            <FeatureImageWrapper>
                                <StaticImage
                                    src="../images/salon/salon2.jpg"
                                    alt=""
                                />
                            </FeatureImageWrapper>
                        </RightWrapper>
                        <SalonDescription size="s">
                            ご来店当日は、WEBでカスタマイズして頂いたリングをご用意しております。その他にも類似リングやおすすめリングもご試着頂けます。また、カスタマイズのリングにお好みのアレンジを加える、オーダーメイドもお気軽にご相談ください。
                        </SalonDescription>
                        <SalonDescription size="s">
                            ご購入の場合も、見学やご相談のみの場合でもカスタマイズリングのご試着やオーダーメイドのご相談は無料で承っております。
                        </SalonDescription>
                    </SalonWrapper>
                    <Table>
                        <Row>
                            <H>
                                <HTxt size="s">住所</HTxt>
                            </H>
                            <D>
                                <Txt size="s">〒150-0001</Txt>
                                <Txt size="s">東京都渋谷区神宮前3−16−16−1F</Txt>
                                <Txt size="xs">
                                    ※表参道駅徒歩８分/JR原宿駅徒歩10分
                                </Txt>
                            </D>
                        </Row>
                        <Row>
                            <H>
                                <HTxt size="s">営業時間</HTxt>
                            </H>
                            <D>
                                <Txt size="s">9:00〜17:00</Txt>
                                <Txt size="xs">※組数限定 完全予約制</Txt>
                            </D>
                        </Row>
                    </Table>
                    <MapWrapper>
                        <iframe
                            allowFullScreen
                            frameBorder="0"
                            height="300"
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6482.5302911212475!2d139.7098847!3d35.6704728!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188ca2f66739b1%3A0x314343a79fb271b2!2z44CSMTUwLTAwMDEg5p2x5Lqs6YO95riL6LC35Yy656We5a6u5YmN77yT5LiB55uu77yR77yW4oiS77yR77yW!5e0!3m2!1sja!2sjp!4v1587174489464!5m2!1sja!2sjp"
                            style={{ border: 0 }}
                            width="100%"
                        />
                    </MapWrapper>
                    <RingBaseSelect marginTop="50px" />
                </Grid>
            </Grid>
        </Layout>
    )
}

const CatchWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    margin: '16px 0',
})
const HeroImageWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '32px',
})
const SalonWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingTop: '48px',
})
const LeftWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
})
const RightWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
})
const SalonTitleWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
})
const SalonTitle = styled(Txt)({
    padding: '8px',
})
const NumberImageWrapper = styled('div')({
    width: '80px',
})
const FeatureImageWrapper = styled('div')({
    width: '80%',
})
const SalonDescription = styled(Txt)({
    padding: '8px',
})
const Table = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '340px',
    margin: '60px auto 16px',
    border: '1px solid #aaaaaa',
    borderBottom: 'none',
})
const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid #aaaaaa',
})
const H = styled('div')({
    width: '100px',
    padding: '8px',
    borderRight: '2px solid #aaaaaa',
    backgroundColor: '#f5f5f5',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
})
const HTxt = styled(Txt)({
    fontWeight: 'bold',
})
const D = styled('div')({
    flex: 1,
    padding: '8px',
    display: 'flex',
    flexDirection: 'column',
})
const MapWrapper = styled('div')({
    padding: '8px',
})

export default SalonPage
